import type { AppProps } from "next/app";
import { Space_Grotesk } from "next/font/google";
import React, { Suspense } from "react";
import "../styles/style.scss";
import "../styles/globals.css";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
const spaceGrotesk = Space_Grotesk({
  subsets: ["latin"]
});
export default function App({
  Component,
  pageProps
}: AppProps) {
  return <main className={`${spaceGrotesk.className}`}>
      <Suspense fallback={<Loading />}>
        <div className="bg-grid relative flex h-full min-h-full flex-col bg-[#141414] text-gray-200 [--grid-opacity:0.08] [--grid-size:100px]">
          <section className="relative z-20 mx-auto w-3/4 2xl:w-1/3">
            <Component {...pageProps} />
            <Footer />
          </section>
        </div>
      </Suspense>
    </main>;
}